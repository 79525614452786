import { AxiosResponse } from "axios";
import {
  ApiResponse
} from "../../types/global.types";
import { apiConfigAppApi } from "../apiConfig";

export async function UnsubscribeById(
  id: string
): Promise<void | AxiosResponse<ApiResponse, any>> {
  return await apiConfigAppApi
    .post<ApiResponse>(`/email/unsubscribe/${id}`)
    .catch((error) => {
      console.log(error);
    });
}
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { UnsubscribeById } from '../services/subscribe/unsubscribe.service';
function Unsubscribe() {
  const { id } = useParams();
  const [message, setMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const UnsubscribeByid = async () => {
    if (id) {
      const response = await UnsubscribeById(id);
      if (response?.data.status) {
        setMessage(response?.data.result?.offer)
      } else {
        setErrorMessage(response?.data.result[0].message);
      }
    }
  }

  return <>
    <div className="subscription">
      <div className="subscription-title">
        We're sorry to see you go!
      </div>
      <div className="subscription-description">
        If you'd like to stop receiving our emails, simply click the button below.
      </div>

      <div className="subscription-buttons">
        {(!message && !errorMessage) ?
          <div className="subscription-button unsubscribe" onClick={UnsubscribeByid}>
            Unsubscribe Me
          </div>
          :
          <>
            {errorMessage ? errorMessage : `You have unsubscribed from ${message}`}
          </>
        }
      </div>

    </div>
  </>;
}

export default Unsubscribe;

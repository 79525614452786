import { useEffect, useState } from "react";
import "./PreCountAndSelectAll.scss";

export interface Props {
  offerLength: number;
  countText: string | undefined;
  selectText: string | undefined;
  borderColor?: string;
  showCheckboxSelect: boolean;
  showSelectAll: boolean;
  showCount?: boolean;
  noOfSelected: number;
  newLook?: boolean;
  padding?: string;
  handleSelectAll: () => void;
  highlightColor?: string;
}

const PreCountAndSelectAll = ({
  offerLength,
  countText,
  borderColor,
  showCheckboxSelect,
  showSelectAll,
  showCount = false,
  handleSelectAll,
  noOfSelected,
  newLook,
  padding,
  selectText,
  highlightColor = "#3ab78f",
}: Props) => {
  const [selectAll, setSelectAll] = useState<boolean>(
    noOfSelected === offerLength
  );

  useEffect(() => {
    setSelectAll(noOfSelected === offerLength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfSelected]);

  return (
    <div
      className={`pre-count-and-select-all ${
        showCount
          ? "pre-count-and-select-all--jb"
          : "pre-count-and-select-all--end"
      }`}
      style={{ padding: padding ? padding : "5px" }}
    >
      {showCount && (
        <div className="selected-count">
          <span>
            {noOfSelected} of {offerLength}{" "}
          </span>
          {countText || "Free gifts"}
        </div>
      )}

      {showSelectAll && (
        <div
          className="select-wrapper"
          onClick={() => {
            setSelectAll(!selectAll);
            handleSelectAll();
          }}
        >
          {newLook && (
            <span className="newlook">{selectText || "Select all"}</span>
          )}
          {showCheckboxSelect ? (
            <div className="select">
              <div
                style={{
                  backgroundColor: selectAll ? highlightColor : "#ffffff33",
                  borderColor: selectAll
                    ? highlightColor
                    : borderColor || "#c6c6c6",
                }}
                className="checkbox"
              >
                {selectAll && (
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1L4 8L1 5"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
            </div>
          ) : (
            <span
              className={
                selectAll
                  ? "switch-toggle switch-toggle--selected"
                  : "switch-toggle"
              }
              style={{
                backgroundColor: selectAll ? highlightColor : "#f1f2f3",
              }}
            >
              <span
                className={
                  selectAll
                    ? "switch-toggle-before switch-toggle-before--selected"
                    : "switch-toggle-before"
                }
              ></span>
            </span>
          )}
          {!newLook && <span>{selectText || "Select all"}</span>}
        </div>
      )}
    </div>
  );
};

export default PreCountAndSelectAll;

import { useEffect, useRef, useState } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { postOfferView } from "../../../../services/offers/offers.service";
import {
  CampaignType,
  Offer,
  OffersIdSlots,
  PlacementStage,
  QueryPostOfferView,
  Template,
  TemplateOptionSettings,
} from "../../../../types/global.types";
import OfferItem from "../offer-item/OfferItem.component";

interface Props {
  settings: TemplateOptionSettings;
  offers: Offer[];
  settingsTemplate: Template;
  campaign: CampaignType;
  displayOnly: boolean;
  campaignId: string;
  placementId: string;
  defaultOpen: boolean;
  placementName: string;
  isInline?: boolean;
  offersCount: string | number;
  openSingle: (
    o: Offer,
    isSave: boolean,
    isAutoEmail: boolean,
    slot: number
  ) => void;
  onAdd: (offer: Offer, value: boolean, slot: number) => void;
}

const SwiperComponent = ({
  settings,
  campaign,
  offers,
  displayOnly,
  defaultOpen,
  campaignId,
  placementId,
  placementName,
  settingsTemplate,
  openSingle,
  onAdd,
}: Props) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [eventSentOnIndex, setEventSentOnIndex] = useState<number[]>([]);
  const [eventOfferIds, setEventOfferIds] = useState<OffersIdSlots[]>([]);

  const refNavPrev = useRef<HTMLDivElement | null>(null);
  const refNavNext = useRef<HTMLDivElement | null>(null);

  const setDefaultOfferImpressionEvent = async (offers: OffersIdSlots[]) => {
    if (eventOfferIds.length < campaign.offers.length) {
      const data: QueryPostOfferView = {
        offers,
        campaignId,
        campaignName: campaign.name || "",
        companyId: campaign.company_Id,
        placementId,
        placementName,
        pageUrl: "",
        templateId: settingsTemplate.identifier,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      };

      await postOfferView(data);
    }
  };

  //set offer id fired events to empty
  useEffect(() => {
    if (!defaultOpen) {
      setEventSentOnIndex([]);
      setEventOfferIds([]);
    }
    // eslint-disable-next-line
  }, [defaultOpen]);

  //set event for default active slide offers
  useEffect(() => {
    if (swiper && defaultOpen) {
      const _ids = offers
        .map((offer: Offer, index: number) => ({
          id: offer.id,
          slot: index + 1,
        }))
        .slice(0, Number(3));
      setDefaultOfferImpressionEvent(_ids);
      setEventOfferIds(_ids);
      setEventSentOnIndex([0]);
    }
    // eslint-disable-next-line
  }, [swiper, defaultOpen]);

  //geting slides based on campaign offers length and slides number of offers
  const numberOfSlides =
    offers.length % 3 === 0 ? offers.length / 3 : offers.length / 3 + 1;
  const getOffersOfSlide = (i: number) =>
    i === 0 ? offers.slice(i, i + 3) : offers.slice(i * 3, i * 3 + 3);
  //set offer view events based on slides that are displayed on active index
  const setOfferViewEvent = (activeIndex: number) => {
    const offersLength = offers.length;

    if (offersLength - 3 * activeIndex >= 3) {
      const viewedOffersId = offers
        .slice(activeIndex * 3, activeIndex * 3 + 3)
        .map((offer: Offer, index: number) => ({
          id: offer.id,
          slot: activeIndex * 3 + (index + 1),
        }));

      if (!eventSentOnIndex.includes(activeIndex)) {
        setDefaultOfferImpressionEvent(viewedOffersId);
        setEventOfferIds(viewedOffersId);
        setEventSentOnIndex((prevState) => [...prevState, activeIndex]);
      }
    } else {
      const viewedOffersId = offers
        .slice(
          activeIndex * 3,
          activeIndex * 3 + (offersLength - 3 * activeIndex)
        )
        .map((offer: Offer, index: number) => ({
          id: offer.id,
          slot: activeIndex * 3 + (index + 1),
        }));
      if (!eventSentOnIndex.includes(activeIndex)) {
        setDefaultOfferImpressionEvent(viewedOffersId);
        setEventOfferIds(viewedOffersId);
        setEventSentOnIndex((prevState) => [...prevState, activeIndex]);
      }
    }

    setEventSentOnIndex((prevState) => [...prevState, activeIndex]);
  };

  return (
    <>
      {campaign?.offers.length > 0 && (
        <Swiper
          className={`swiper-container`}
          modules={[Navigation, Pagination]}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
          }}
          slidesPerView={1}
          spaceBetween={20}
          loop={true}
          onSwiper={(s: SwiperClass) => setSwiper(s)}
          navigation={{
            prevEl: refNavPrev.current,
            nextEl: refNavNext.current,
          }}
          onBeforeInit={(swiper: any) => {
            swiper.params.navigation.prevEl = refNavPrev.current;
            swiper.params.navigation.nextEl = refNavNext.current;
          }}
          onSlidePrevTransitionEnd={(_swiper: SwiperClass) => {
            const acitveIndex = _swiper.realIndex;
            setOfferViewEvent(acitveIndex);
          }}
          onSlideNextTransitionEnd={(_swiper: SwiperClass) => {
            const acitveIndex = _swiper.realIndex;
            setOfferViewEvent(acitveIndex);
          }}
        >
          {Array.from({ length: numberOfSlides }).map(
            (_: unknown, i: number) => {
              return (
                <SwiperSlide
                  key={`slide${i}`}
                  style={{ color: settings?.dealLayout.textColor }}
                >
                  <div
                    className={`swiper-item ${displayOnly &&
                        campaign.campaignGeneralOptions.showAddToOrder
                        ? "display"
                        : "getbutton"
                      }`}
                  >
                    {getOffersOfSlide(i).map((offer: Offer) => {
                      return (
                        <OfferItem
                          key={`offeritem${i}-${offer.id}`}
                          eventOfferIds={eventOfferIds}
                          offer={offer}
                          settings={settings.dealLayout}
                          settingsTemplate={settingsTemplate}
                          onAdd={onAdd}
                          displayOnly={displayOnly}
                          campaign={campaign}
                          retailerId={campaign?.company_Id}
                          campaignId={campaignId}
                          placementId={placementId}
                          placementName={placementName}
                          openSingle={openSingle}
                          otherSettings={settings.otherSettings}
                        />
                      );
                    })}
                  </div>
                </SwiperSlide>
              );
            }
          )}

          <div className="swiper-navigation">
            <div className="swiper-button-prev" ref={refNavPrev}>
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="17"
                  cy="17"
                  r="17"
                  transform="rotate(-180 17 17)"
                  fill={settings.dealLayout.backgroundColor}
                />
                <rect
                  x="18.6094"
                  y="23"
                  width="8.6192"
                  height="1.72384"
                  rx="0.86192"
                  transform="rotate(-135 18.6094 23)"
                  fill={settings.dealLayout.textColor}
                />
                <rect
                  x="20.3135"
                  y="12.2188"
                  width="8.6192"
                  height="1.72384"
                  rx="0.86192"
                  transform="rotate(135 20.3135 12.2188)"
                  fill={settings.dealLayout.textColor}
                />
              </svg>
            </div>
            <div className="swiper-pagination"></div>
            <div className="swiper-button-next" ref={refNavNext}>
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="17"
                  cy="17"
                  r="17"
                  fill={settings.dealLayout.backgroundColor}
                />
                <rect
                  x="15.3906"
                  y="11"
                  width="8.6192"
                  height="1.72384"
                  rx="0.86192"
                  transform="rotate(45 15.3906 11)"
                  fill={settings.dealLayout.textColor}
                />
                <rect
                  x="14.1719"
                  y="22.2656"
                  width="8.6192"
                  height="1.72384"
                  rx="0.86192"
                  transform="rotate(-45 14.1719 22.2656)"
                  fill={settings.dealLayout.textColor}
                />
              </svg>
            </div>
          </div>
        </Swiper>
      )}
    </>
  );
};

export default SwiperComponent;
